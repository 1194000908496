<template>
  <div id="app">
    <CRT v-bind:mobileMode="this.mobileMode" />

    <div class="content">
      <Header v-bind:mobileMode="this.mobileMode" :year="this.year" />
        <div class="anchor" id="welcome" />
        <Welcome />
      <Projects v-bind:mobileMode="this.mobileMode" />

      <Footer v-bind:mobileMode="this.mobileMode" :year="this.year"/>
    </div>
  </div>
</template>



<script>
  import CRT from '@/components/front/CRT.vue';
  import Header from '@/components/navigation/Header.vue';
  import Footer from '@/components/content/Footer.vue';

  import Welcome from '@/components/content/Welcome.vue';
  import Projects from '@/components/content/Projects.vue';

  export default {
    name: 'App',
    components: {
      CRT,
      Header,
      Footer,
      Welcome,
      Projects
    },
    data() {
      return {
        mobileMode: false,
        year: ""
      };
    },
    mounted:function() {
      this.mobileCheck();
      this.retrieveCurrentYear();
    },
    created() {
      window.addEventListener("resize", this.mobileCheck);
    },
    methods: {
      mobileCheck() {
        ///Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || 
        if (window.innerWidth <= 1000) {
          this.mobileMode = true;
        } else {
          this.mobileMode = false;
        }
      },
      retrieveCurrentYear() {
        const currentYear = new Date().getFullYear();
        this.year = currentYear.toString();
      },
    }
  }
</script>

<style>

::-webkit-scrollbar {
    display: none;
}

html {
  --main-color: rgb(0, 160, 160);
  --highlight-color: rgb(70, 200, 200);

  overflow: hidden;
  height: 100%;
}
body {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;
  perspective: 1px;
  transform-style: preserve-3d;
  scroll-behavior: smooth;
}
@font-face {
  font-family: "Monogram";
  src: local("Monogram"), 
    url(./fonts/dogica.ttf) format("opentype");
}
@font-face {
  font-family: "Neon";
  src: local("Neon"), 
    url(./fonts/title.ttf) format("opentype");
}
h3 {
    font-family: 'Monogram', Helvetica, Arial, sans-serif;
    margin-bottom: 10px !important;
  line-height: 150%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.anchor {
  position:absolute;
  top: 0px;
}

.content {
  width: 100%;
  top: 100%;
  position: relative;
  min-height: 100vh;
  transform-style: inherit;
  z-index: -1;
  position: absolute;
  background-image: url("assets/sprites/BackgroundTile.png");
  background-color: #222222;
}

.container {
  max-width: 1000px;
  background-color: #444444;
  box-shadow: 0px 0px 20px #000000;
  margin: 0 auto !important;
  color:white;
  padding-bottom: 100px;
}
section {
  position: relative;
  width: 100%;
}
section h1 {
  font-family: 'Monogram', Helvetica, Arial, sans-serif;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

p a {
  color: var(--main-color);
}

h4 {
    font-family: 'Monogram', Helvetica, Arial, sans-serif;
    font-size: 3mm;
  line-height: 150%;
}

button {
    font-family: 'Monogram', Helvetica, Arial, sans-serif;
  line-height: 150%;
    
    float:left;
    transition-duration: 0.3s;
    font-size: 5mm;
    width: fit-content;
    padding: 1vh;

    background-color: var(--main-color);
    border:2px solid var(--main-color);
    color:white
}
button:hover {
    background-color: #fff;
    color: black;
    cursor: pointer;
}
</style>
