<template>
  <div>
    <section id="projects" class="still">
      <h1>Experience timeline</h1>
    </section>
    <section class="glass">
      <SparklyBackground />

      <div class="container">
        <div v-for="project in projectDataList" :key="project.title" class="entry">
          
          <div v-if="!mobileMode" class="entry_header"> 
            <h2>{{project.title}}</h2>
            <i>{{project.type}}</i>
            <h3 style="float:right;">{{project.yearStamp}}</h3>
          </div>
          <div v-if="mobileMode" class="entry_header_mb"> 
            <h2>{{project.title}}</h2>
            <i>{{project.type}}</i>
            <h3>{{project.yearStamp}}</h3>
          </div>

          <p v-html="project.description"></p>

          <img class="divider" src="@/assets/sprites/Divider.png">
        </div>
        
        <!-- <div style="height:100px"> </div> -->
      </div>
    </section>
  </div>
</template>

<script>
import SparklyBackground from '../background/SparklyBackground.vue';
export default {
  name: 'Projects',
  components: {
    SparklyBackground
  },
  props: {
      mobileMode: Boolean
  },
  data() {
    return {
      projectDataList: []
    };
  },
  mounted:function(){
    this.getProjectData();
  },
  methods: {
    getProjectData() {
      fetch("projects.json")
        .then(response => response.json())
        .then(data => (this.projectDataList = data));
    }
  }
}
</script>

<style>
.still {
  min-height: 200px;
  background: #222;
  box-shadow: 0 0 20px #000;
  z-index: 1;
  position: relative;
  width: 100%;
}
.still h1 {
  line-height: 150%;
  font-size: 32px;
  color: #fff;
}

.justifier {
  width: inherit;
  position: absolute;
  top: 100px;
}

.backgroundTitle {
  transform: translateZ(-.1px)translate(-39%);
  z-index: -2;
  filter: brightness(30%);
}
.glass .container {
  background-color: rgba(128, 128, 128, .15);
  backdrop-filter: blur(5px);
  box-shadow: 0px 0px 20px rgb(32, 32, 32);
  padding-top: 100px;
}

.entry {
  text-align: left;
  color: white;
}
.entry h2 {
  font-family: 'Monogram', Helvetica, Arial, sans-serif;
  line-height: 150%;
  color: white;
  padding-bottom:10px;
  line-height: 150%;
}
.entry p {
  padding-top: 25px;
}
.entry .divider {
  padding-left: 100%; 
  padding-top: 25px; 
  padding-bottom: 25px; 
  transform: translate(-50%);
}
.entry img{
  max-width: 100%;
}
.entry video{
  max-width: 100%;
  min-width: 100%;
  padding-top: 25px;
}

.entry_header {
  padding:1rem;
  background-color: rgba(0, 160, 160, .6);
}

.entry_header_mb {
  padding:1rem;
  background-color: rgba(0, 160, 160, .6);
  text-align: center;
}
.entry_header_mb h3 {
  padding-top: 5mm;
}

</style>