<template>
    <div>
        <div class="social_block">
            <a href='https://www.linkedin.com/in/kyranio/'><button class="social"><img src="@/assets/sprites/LinkedIn.png"></button></a>
            <a href='https://github.com/Kyranio'><button class="social"><img src="@/assets/sprites/Github.png"></button></a>
            <!-- <a href='https://www.instagram.com/kyran.io/'><button class="social"><img src="@/assets/sprites/Instagram.png"></button></a>
            <a href='https://www.youtube.com/channel/UCrd1pEKaiutXu-qA3758Eqg'><button class="social"><img src="@/assets/sprites/YouTube.png"></button></a>
            <a href='https://twitter.com/Kyranio'><button class="social"><img src="@/assets/sprites/Twitter.png"></button></a> -->
        </div>
    </div>
</template>

<script>
export default {
  name: 'Social'
}
</script>

<style>
.social {
  margin: 5px !important;
  height:60px;
  width:60px;
  border-radius: 30px;
  background-color: var(--main-color);
  overflow: hidden;
  border-left: none;
  border-right: none;
}
.social img {
  width: 35px;
}
.social:hover {
  background-color: var(--highlight-color);
  cursor:pointer;
}
.social_block {
  display: flex;
  justify-content: center;
}
</style>