
<template>
  <section class="orbs_background">
    <div v-for="bubble in bubbles" v-bind:class="bubble.class" :style="bubble.style" :key="bubble.index"></div>
  </section>
</template>

<script>
  function random(min, max) {
    return Math.random() * (max - min) + min;
  }

  export default  {
      name: "CenterOrbs",
      computed: {
        bubbles: function () {
          var arr = [];
          var key = 0;
          for (var z = 1; z <= 3; z++)
            {  
              for(var c = 0; c <= 10; c++) {
                var size = Math.ceil(random(250, 500))
                arr.push({
                  index: key++,
                  class: c > 5 ? "orbUp" : "orbDown",
                  style: {
                    left: random(0, 100) + '%',
                    width: size  + 'px', height: size  + 'px', 
                    bottom: '-'+size  + 'px',
                    animationDelay: random(0, -((size / z) / 3)) + 's',
                    animationDuration: (size / z) / 2 + 's',
                    background: 'hsl(180, 100%, ' + (5 * z) + '%)'
                  }
                });
              }
            }
          return arr;
        }
      }
  }
</script>

<style>
/* BACKGROUND STYLE */
@keyframes animateUpward {
    0%{
        transform:scale(1);
        top: 100%;
    }
    100%{
        transform:scale(0);
        top: 25%;
    }
}
@keyframes animateDownward {
    0%{
        transform:scale(1) translateY(-100%);
        top: 0;
    }
    100%{
        transform:scale(0) translateY(-100%);
        top: 75%;
    }
}

.orbs_background {
  position: absolute;
  min-height: 100%;
  overflow: hidden;
}
.orbs_background:before {
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  display: block;
  content:'';

  background: #000a0e;
  min-height: 100%;
}
.orbs_background div {
  position: absolute;
  display: block;
}
.orbs_background .orbUp {
    animation: animateUpward ease-in infinite;
}
.orbs_background .orbDown {
    animation: animateDownward ease-in infinite;
}

</style>
