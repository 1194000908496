
<template>
  <section class="background">
    <div v-for="sparkle in sparkles" v-bind:style="sparkle.style" :key="sparkle.index" :id="'sprkl_'+sparkle.index"></div>
  </section>
</template>

<script>

  function random(min, max) {
    return Math.random() * (max - min) + min;
  }
  
  export default  {
    name: "SparklyBackground",
    computed: {
      sparkles: function () {
        return generateSparkles();
      }
    }
  }

  function generateSparkles() 
  {
    var sparkleArray = [];
    var key = 0;
    for(var sparkle = 0; sparkle <= 30; sparkle++) {
      var size = 25 
      sparkleArray.push({
        index: key++,
        style: {
          left: random(0, 100) + '%',
          top: random(0, 100) + '%',
          width: size  + 'px', height: size  + 'px', 
          position: "absolute",
          animationDelay: random(1, 10) + 's',
        }
      });
    }

    return sparkleArray;
  }
</script>

<style>

@keyframes sparkling {
    from { background-position: 0px; }
    to { background-position: -1000px; }
}

.background {
  position: absolute;
  min-height: 100%;
  overflow: hidden;
}
.background:before {
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  display: block;
  content:'';

  background: #000a0e;
  background-image: url("../../assets/sprites/BackgroundTile.png");
  min-height: 100%;
}
.background div {
    position: absolute;
    display: block;
    background: url("../../assets/sprites/Sparkle.png");
    background-position: -700px;
    animation: sparkling 3750ms steps(40) infinite;
}

</style>
