<template>
<div>
    <transition name="fade" mode="out-in">
        <div v-if="sent">
            <h3>Message sent! I'll get back to you asap!</h3>
        </div>
    </transition>
    <form style="padding-top:25px;" v-if="!sent" @submit="onSubmit">
        <label for="fname">Full name</label>
        <input type="text" id="fname" name="fname" v-model="form.name" required>
        <label for="email">E-mail address</label>
        <input type="text" id="email" name="email" v-model="form.email" required>
        <label for="subject">Subject</label>
        <input type="text" id="subject" name="subject" v-model="form.subject" required>
        <label for="message">Message</label>
        <ResizeAuto>
            <template v-slot:default="{resize}">
                <textarea class="textarea" id="message" name="message" v-model="form.message" @input="resize" required />
            </template>
        </ResizeAuto>
        <button type="submit">Submit</button>
    </form>
</div>
</template>

<script>
import ResizeAuto from "@/components/helpers/ResizeAuto.vue";
import axios from 'axios'
// const querystring = require("querystring");

export default {
    name: "MailForm",
    components: {
        ResizeAuto
    },
    data() {
        return {
            sent: false,
            form: {
                name: "",
                email: "",
                subject: "",
                message: ""
            }
        }
    },
    methods: {
      onSubmit(e) {
          e.preventDefault();
          axios
             .post(
                 "https://www.kyran.io/mail.php",
                //   querystring.stringify(this.form)
             )
         
          
          this.sent = true;
      }
    }
};
</script>

<style>
input[type=text] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0 !important;
  box-sizing: border-box;
  border: 2px var(--main-color);
}
textarea {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0 !important;
  box-sizing: border-box;
  border: 2px var(--main-color);
  resize: vertical;
}
form button {
    float:left;

    background-color: var(--main-color);
    border:2px solid var(--main-color);
    color:white
}
form button:hover {
    background-color: #fff;
    color: black;
}
</style>