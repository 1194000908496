<template>
  <div class="footer">
    <div class="fade" />

      <div class="container" id="contact">
      <table v-if="!mobileMode" style="width: 100%; padding-top: 50px;">
        <tr>
          <td style="vertical-align: top;">

            <h3> Follow me elsewhere: </h3>
            <Social />

          </td>
          <td style="width: 50%;">
            <h3>Contact me:</h3>

            <MailForm />

          </td>
        </tr>
      </table>

      <table v-if="mobileMode" style="padding-top: 50px; margin: 10px !important">
          <tr>

            <h3> Follow me elsewhere: </h3>
            <Social />

          </tr>
          <tr>
            <h3 style="padding-top: 2cm;">Contact me:</h3>
            <MailForm />

          </tr>
      </table>
      
      <img style="padding-top: 50px;" src="@/assets/sprites/Divider.png">

      <h4 style="font-weight: normal;">© Kyran Oostra {{year}}</h4>

      </div>

  </div>
</template>

<script>
import Social from '@/components/content/Social.vue';
import MailForm from "@/components/helpers/MailForm.vue";
export default {
  name: 'Footer',
  props: {
      mobileMode: Boolean,
      year: String
  },
  components: {
    Social,
    MailForm
  },
}
</script>

<style>

.footer {
  font-family: 'Monogram', Helvetica, Arial, sans-serif;
  line-height: 150%;
  background-color: black;
  color: white;
  z-index: 1;
}
.footer .container{
  background-color: transparent;
  box-shadow: none;
  min-height: 0px;
}

table {
  font-family: 'Monogram', Helvetica, Arial, sans-serif;
  line-height: 150%;
}

label {
  float: left;
}

.fade {
  background-image: url("../../assets/sprites/Fade.png");
  background-repeat: repeat-x;
  filter: brightness(0%);

  position:absolute;
  transform:translateY(-100%);
  height:64px;
  width:100%;
  left:0;
}

</style>