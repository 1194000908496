<template>
    <section class="CRT">
      <CenterOrbs />
      
        <div class="screen_content">
            <div></div>
        </div>
        <div class="screen_grid" />
        <section class="jumbotron">
            <img src="@/assets/logo.png" width="20%" v-if="!mobileMode">
            <img src="@/assets/logo.png" width="80%" v-else>
        </section>
    <button onclick="location.href='/#welcome'" class="doorman">
        <img src="@/assets/sprites/Extra/PointyArrowGif.gif">
        <b>Scroll down for more</b>
        <img src="@/assets/sprites/Extra/PointyArrowGif.gif">
    </button>
    </section>
</template>

<script>
import CenterOrbs from '@/components/background/CenterOrbs.vue';
export default {
  name: 'CRT',
  components: {
    CenterOrbs
  },
  props: {
      mobileMode: Boolean
  },
}
</script>

<style>

.CRT 
{
    width: 100%;
    height: 100%;
    position:absolute;
    overflow:hidden;
}

.screen_content 
{
    position:absolute;
    width: 100%;
    height: 100%;

    background-color: rgb(40, 40, 40);

    z-index: -2;
}
.screen_content div 
{
    z-index: -1;
}

.screen_grid 
{
    width: 100%;
    height: 100%;
    position: absolute;

    background-color: transparent;
    background-size: 20px;
    background-image: url("../../assets/extras/logo_grid_mask_tiny_black.png");
}

.jumbotron
{
    width: 100%;
    top: 50%;
    transform: translatey(-50%);
}

@keyframes doormanEntrance {
    from {
        bottom: -2cm;
    }
    to {
        bottom: 1cm;
    }
}

.doorman {
    display: flex;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    bottom:-2cm;

    vertical-align: middle;
    text-align: center;
    font-size: 16px;

    background-color: var(--main-color);
    border:2px solid var(--main-color);
    color:white;

    animation: doormanEntrance 1s ease-out forwards;
    animation-delay: 5s;
}
.doorman:hover {
    background-color: #fff;
    color: black;
}
.doorman img{
    max-height: 32px;
}
.doorman b{

    padding-left: 3mm;
    padding-right: 3mm;
}
</style>