<script>
import { ref } from 'vue'

// eslint-disable-next-line no-unused-vars
const mO = ref(false);

export default {
  name: 'Header',
  props: {
      mobileMode: Boolean,
      year: String
  },
  data: () => ({
    menuOpen: mO
  })
}
</script>

<template>
    <div class="header">
        <button class="logo" onclick="window.location.href='#app'">
            <img src="@/assets/logo.png">
        </button >

        <div class="desktop_nav" v-if="!mobileMode">
            <button onclick="window.location.href='#welcome'">Welcome</button>
            <button onclick="window.location.href='#projects'">Experience</button>
            <button onclick="window.location.href='#contact'">Contact</button>
        </div>
        <div class="mobile_nav"  v-if="mobileMode">
            <button class="hamburger_button" @click="menuOpen = !menuOpen">
                <img src="../../assets/sprites/UI_Buttons/Hamburger.png"/>
            </button>
            <div class="side_menu" v-bind:class="{'side_menu_shown': menuOpen}">
                <div style="padding-top: calc(50vh - 4cm);">
                    <button @click="menuOpen = false" onclick="window.location.href='#welcome'">Welcome</button>
                    <button @click="menuOpen = false" onclick="window.location.href='#projects'">Experience</button>
                    <button @click="menuOpen = false" onclick="window.location.href='#contact'">Contact</button>
                </div>
                
                <h4>
                    <div style="width: 100%;"><img src="@/assets/sprites/Divider.png"></div>
                    © Kyran Oostra {{year}}
                </h4>
            </div>
        </div>
    </div>
</template>

<style>
.header{
    z-index: 5;
    width: 100%;
    height: 2cm;
    top: 0;
    position: sticky;
    display: flex;
    background-color: #444444;

    box-shadow: 0px 0px 20px black;
    font-family: 'Monogram', Helvetica, Arial, sans-serif;
  line-height: 150%;
}
.header button {
    transition-duration: 0.3s;
    font-size: 5mm;
    width: fit-content;
    color:white;
    background-color: transparent;
    height: 100%;

    font-family: 'Monogram', Helvetica, Arial, sans-serif;
  line-height: 150%;
}
.header button:hover {
    background-color: var(--main-color);
}

.desktop_nav{
    display: flex;
    justify-content: center;
    background-color: transparent;
    width: 100%;
    height: 100%;
}
.desktop_nav button{
    border: 0;
    border-top: 2px solid var(--main-color);
    border-bottom: 2px solid var(--main-color);
    height:100%;
}

.mobile_nav{
    display: flex;
    justify-content: right;
    width: 100%;
    height: 100%;
    background-color: transparent;
    margin:auto !important;
}
.mobile_nav button{
    vertical-align: middle;
    border: 0;
}
.mobile_nav img{
    max-height: 100%;
}

.side_menu {
    position: absolute !important;
    height: 100vh;
    width: 100vw;
    top: 2cm;
    background: #222;

    transform: translateX(100%);
    transition-timing-function: ease-in;
    transition: 0.2s;
}
.side_menu_shown {
    transform: translateX(0%);
    transition: 0.25s;
    transition-timing-function: ease-out;
}
.side_menu button {
    width: 100%;
    height: auto;
}
.side_menu h4 {
    position: absolute;
    bottom: 100px;
    width: 100%;
    color: white;
    font-weight: normal;
}

.header .logo {
    position: absolute;
    display: flex;
    float: left;
    transition-duration: 0.3s;
    height: 100%;
    font-size: 1cm;
    top: 50%;
    transform: translate(0, -50%);
    align-items: center;
    font-weight: bold;
    padding: 0 2vh;
    border-left: none;
    border-right: none;
}
.header .logo:hover {
    background-color: var(--main-color);
    color:white;
}
.header .logo:hover span {
    color: white;
}
.header .logo img{
    height:60%;
}
</style>