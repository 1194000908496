<template>
    <div class="container">
    <div class="holder">
    <section class="welcome">
        <div class="title">
            <!-- <img style="position: absolute; display: block; transform: scale(0.3); top: -135px; left: -97px;" src="../../assets/sprites/TV.gif">  -->
        Hello, world!</div>
        <p>
            Hello, welcome to my website! Take a look around, I have build it up myself! I hope you like it, otherwise I would love for you to tell me about anything that is (or simply seems) broken. Luckily, you can find a few ways to contact me right here on this website.
        </p>
        <h3 style="padding-top: 3cm;">FAQ</h3>
        <p> 
            <b>What is this website?</b> <br> This website is simply put, my portfolio. It is supposed to represent myself, showing all the projects I have worked on and provide an easy way to contact me.
            <br><br>
            <b>Who are you?</b> <br> So, my name is Kyran and I really like videogames. Like, a lot! I actually followed a course to become a full-fledged game developer! However, as I continued learning about computers I realized that the possibilities don't just end with playing games. So, I continued to become a software engineer!
            <br><br>
            <b>What have you been working on?</b> <br> You should check out the <a href="#projects">projects section</a> below! Or, check out my social media accounts! I will share the latest on there.
            <br><br>
            <b>How can I contact you?</b> <br> You can <a href="#contact">send me an email</a> by filling out the form at the bottom. I will get back to you asap! Otherwise, you can always contact me via my social media pages!
            <br><br>
        </p>


        <img src="@/assets/sprites/Divider.png?a=2">
        <h3>Follow me elsewhere</h3>
        <Social />
    </section>
    </div>
    </div>
</template>

<script>
import Social from '@/components/content/Social.vue';
export default {
  name: 'Welcome',
  components: {
    Social
  }
}
</script>

<style>
p {
    text-align: left;
    margin: 5mm !important;
}

.holder {
    padding-top: 30vh;
    padding-bottom: 30vh;
}

.title {
    font-family: 'Monogram', Helvetica, Arial, sans-serif;
    line-height: 150%;
    font-size: 32px;
    background-color: rgb(0, 160, 160);
    color: white;
    text-shadow: 3px 3px black;
    padding: 2rem;
    margin-bottom: 20px !important;
}
</style>